import InputError from '@/components/atoms/Inputs/InputError'
import {isValidBirthDate} from '@/functions/utils'
import cn from 'classnames'
import {Field, FieldProps} from 'formik'
import React, {useState} from 'react'
import Icon from '../../Icon/Icon'
import styles from './Text.module.scss'

interface TextProps {
  className?: string
  description?: string
  id: string
  isRequired?: boolean
  label: string
  type: string
  placeholder?: string
  errorMessage?: string
  defaultValue?: string
  setFieldValue?: (field: string, value: any) => void
  extensionFieldId?: string
  fullWidth?: boolean
  extraPadding?: boolean
  konto?: boolean
}

export default function Text({
  className,
  description,
  id,
  isRequired = false,
  label,
  type,
  errorMessage,
  setFieldValue,
  fullWidth,
  extraPadding,
  konto,
  extensionFieldId = '',
  defaultValue
}: TextProps) {
  const [value, setValue] = useState('' || defaultValue)
  const [showError, setShowError] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <div
      className={cn(
        styles.text,
        className,
        type === 'HIDDEN' ? styles.isHidden : '',
        fullWidth && styles.fullWidth,
        showError ? styles.isError : '',
        extraPadding ? styles.extraPadding : '',
        konto && [styles.konto]
      )}
    >
      <div className={styles.textContainer}>
        {label && type !== 'HIDDEN' && (
          <label htmlFor={id}>
            {label} {isRequired && '*'}
          </label>
        )}
        <Field name={id}>
          {({field}: FieldProps<any>) => (
            <div className={styles.inputWrapper}>
              <input
                {...field}
                aria-required={isRequired}
                id={id}
                required={isRequired}
                type={passwordVisible ? 'text' : type}
                onInvalid={() => setShowError(true)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue(e.target.value)
                  setFieldValue &&
                    setFieldValue(`${id}${extensionFieldId}`, e.target.value)
                  e.target.value === ''
                    ? setShowError(true)
                    : setShowError(false)
                  type === 'EMAIL' &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    e.target.value
                  )
                    ? setShowError(true)
                    : setShowError(false)
                  id === 'customerACF.birthdate' &&
                  !isValidBirthDate(e.target.value)
                    ? setShowError(true)
                    : setShowError(false)
                }}
                value={type === 'EMAIL' ? value?.toLowerCase() : value}
                autoComplete={type === 'password' ? 'new-password' : ''}
              />
              {type === 'password' && (
                <span className={styles.passwordVisible}>
                  <Icon
                    icon={passwordVisible ? 'visibility_off' : 'visible'}
                    className={styles.icon}
                    size={20}
                    sizeViewbox={20}
                    onClick={togglePasswordVisibility}
                  />
                </span>
              )}
            </div>
          )}
        </Field>
      </div>
      {description && <p className={styles.description}>{description}</p>}
      {showError && <InputError name={errorMessage} />}
    </div>
  )
}
